import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import pic1 from '../../images/texure2.jpg'
import pic2 from '../../images/texure3.jpg'
import pic3 from '../../images/texure4.png'
import pic4 from '../../images/texure5.jpg'

export default class TraceSource extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='物体纹理识别' />
        <div className='core-tech-wrap texure-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>纹理识别技术概述</h2>
            <p className='description-text'>
              易见纹语科技采用自有知识产权的国际领先的人工智能纹理识别技术，对被溯源产品本身的天然纹理（如：茶饼、火腿等），或工业
              合成纹理（如：橡胶、钢缆等）进行识别，在生产阶段为每件产品建立身份信息。该身份信息具有唯一性,并将伴随该产品的整个生命周
              期，不可能被篡改。
            </p>
            <p className='description-text'>
              易见纹语的人工智能纹理识别算法能从产品图片中随机选取近千个特征点，并生成高维矢量矩阵，记录该产品的纹理特征，并上传到
              区块链存证。这个算法经过长时间的数百万样本的训练，已经非常强大。即使被拍摄产品处于不同的光场,或者拍摄环境中有其它元素的
              干扰，或者产品因长期存放出现了变色、变蓬松等情况，甚至在产品部分缺失的情况下，算法仍能非常准确的从数以亿计的产品图片准确
              的定位到它。
            </p>

            <div className='trace-source'>
              <img style={{ marginTop: '5rem' }} src={pic1} />
            </div>
          </div>
        </div>

        <div className='core-tech-wrap texure-wrap texure-bg'>
          <div className='main-con'>
            <div className='trace-source'>
              <h2>技术指标</h2>
              <p className='description-text'>
                目前，在普洱茶上做了大规模的测试，1 ：
                1匹配的精度可以达到99.6%。即使普洱茶部分缺失的情况，仍然可以做到97.8%的精度。
                在实际使用中，基于不同的原因，用户上传的图像存在质量的巨大差异。易见纹语科技进一步通过使用较多的特征点数量、KNN双向验
                证、亮度均衡、多级分辨率等等技术来进一步提高算法的鲁棒性。在大规模搜索问题上，仍然能够达到98.85%的精度，进一步验证了所
                提出的纹理识别算法的可行性。
              </p>
            </div>
          </div>
        </div>
        <div className='core-tech-wrap texure-wrap'>
          <img src={pic2} />
        </div>

        <div className='core-tech-wrap texure-wrap'>
          <div className='main-con'>
            <h2>技术应用</h2>
            <p className='description-text'>
              我们首先将溯源技术应用到了目前假冒伪劣、以次充好比较混乱的普洱茶行业，截至目前，已经为云南龙生，安宁海湾，昆明茶厂等
              60多家企业提供溯源服务，总计录入茶饼200多万饼。针对于中小型企业，我们提供T本化的纹录仪设备，对于大型茶企,我们提供自
              动化录入方案,可便捷地与企业目前地自动化流水线相结合,并结合边缘计算技术，确保自动化录入地稳定和高效。
            </p>
            <p className='description-text'>
              除了普洱茶，我们在白茶、燕窝、火腿等领域都进行了实验性应用，并取得了不错地实验效果，具备商业推广条件。除了农业领域，
              在工业领域也存大量地纹理特征，例如电缆的截面、橡胶原料块表面,有些还是三维纹理,这些特征纹理都可以用来做产品的追溯。
            </p>

            <div className='trace-source'>
              <img style={{ marginTop: '5rem' }} src={pic3} />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap texure-wrap'>
          <img src={pic4} />
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
